import * as React from 'react';
import { inject } from 'mobx-react';
import { observer } from 'mobx-react-lite';
import { Input, Form, Row, Col, FormInstance } from 'antd';
import { STORE_RULE_EDIT } from '../constants';
import { ElasticSearchScriptQueryRule } from '../models';
import { RuleEditVisualStore } from '../stores';
import { RuleEditGeneralSettings } from '.';
import { BlockTypesDropdown } from '../../common/components/BlockTypesDropdown';
import RuleType from '../types/RuleType';

type Props = {
    store: RuleEditVisualStore;
    selectType?: (type: RuleType) => void;
    form: FormInstance
};

const RuleEditElasticSearchScriptQueryForm: React.FC<Props> = ({ store, selectType, form }) => {
    const rule = store.editableRule as ElasticSearchScriptQueryRule;

    const getInitialValues = React.useCallback(() => {
        return {
            name: rule.name,
            tag: rule.tag,
            script: rule.script,
            priority: rule.priority,
            description: rule.description,
            overridePriority: rule.overridePriority,
            excludedBlockTypes: rule.excludedBlockTypes
        };
    }, [rule]);

    React.useEffect(() => {
        form.setFieldsValue(getInitialValues());
    }, [form, getInitialValues]);

    const trimScript = () => {
        const script = form.getFieldValue('script');

        if (script) {
            form.setFieldsValue({ script: script.trim() });
        }
    };

    return (
        <>
            {store.error.map((e, i) => (
                <h4 key={e + i} style={{ color: 'red' }}>
                    {e}
                </h4>
            ))}
            <Form form={form} layout="vertical" className="rule-edit-form" data-id="rule-edit-form">
                <RuleEditGeneralSettings store={store} selectType={selectType} form={form} />
                <Row gutter={16}>
                    <Col className="gutter-row" span={24}>
                        <Form.Item
                            label="Script"
                            name="script"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input script!'
                                }
                            ]}
                            data-id="rule-script-input"
                        >
                            <Input.TextArea style={{ height: 'unset' }} rows={4} onBlur={trimScript} />
                        </Form.Item>
                    </Col>
                    <Col className="gutter-row" span={12}>
                        <BlockTypesDropdown mode="multiple" />
                    </Col>
                </Row>
            </Form>
        </>
    );
};

export default inject(STORE_RULE_EDIT)(observer(RuleEditElasticSearchScriptQueryForm));
