import { gql } from 'apollo-boost';
import { execQuery } from '../../common/services/DataService';
import {
    IotaExtension,
    InputBinding,
    ApplicationDefinitionSaveSuccessModel,
    ApiOperationInfo,
    ApiApplicationCreateModel
} from '../types';
import { ApplicationDefinitionsResult } from '../types';
import ApplicationDefinitionsGroupModel from '../types/ApplicationDefinitionsGroupModel';
import ApplicationDefinitionGroupsResult from '../types/ApplicationDefinitionGroupsResult';
import { ApplicationDefinitionBase } from '../types/ApplicationDefinition';
import appClient from '../../common/services/AppClient';
import { ResultApi } from '../../common/services/AppClient';
import security from '../../common/services/SecurityService';
import { Utils } from '../../common/services/Utils';
import { ApplicationDefinitionPreviewBindingResponse } from '../types/ApplicationDefinitionPreviewBindingModel';
import ApplicationDefinitionFactory from '../misc/ApplicationDefinitionFactory';
import { RcFile } from 'antd/lib/upload';

export default class ApplicationDefinitionsService {
    async getAllApplicationDefinitions(): Promise<ApplicationDefinitionBase[]> {
        const result = await execQuery<ApplicationDefinitionsResult>({
            query: gql`
                query getAppDefinitions {
                    getAppDefinitions {
                        id
                        type
                        name
                        projectId
                        applicationId
                        workflowId
                        state
                        settings
                        meta
                        settingValues
                        iconFileId
                        capabilities

                        ... on ApplicationDefinition {
                            bindings
                        }

                        ... on ApplicationDefinitionConditional {
                            conditions
                            inputGroups
                        }
                    }
                }
            `,
            fetchPolicy: 'network-only'
        });
        
        if (result.errors) {
            console.log(result.errors);
            throw result.errors[0];
        }

        return result.data.getAppDefinitions.map(ApplicationDefinitionFactory.createApplicationDefinition) || [];
    }

    async getApplicationDefinitionsForProject(projectId: string): Promise<ApplicationDefinitionBase[]> {
        const result = await execQuery<ApplicationDefinitionsResult>({
            query: gql`
                query getAppDefinitions($projectId: String!) {
                    getAppDefinitions(projectId: $projectId) {
                        id
                        type
                        name
                        projectId
                        extension
                        applicationId
                        workflowId
                        state
                        settings
                        meta
                        settingValues
                        iconFileId
                        capabilities

                        ... on ApplicationDefinition {
                            bindings
                        }

                        ... on ApplicationDefinitionConditional {
                            conditions
                            inputGroups
                        }
                    }
                }
            `,
            variables: {
                projectId: projectId
            },
            fetchPolicy: 'network-only'
        });

        if (result.errors) {
            console.log(result.errors);
            throw result.errors[0];
        }

        return result.data.getAppDefinitions.map(ApplicationDefinitionFactory.createApplicationDefinition) || [];
    }

    async getApplicationDefinition(projectId: string, id: string): Promise<ApplicationDefinitionBase | null> {
        const result = await execQuery<ApplicationDefinitionsResult>({
            query: gql`
                query getAppDefinitions($projectId: String!, $id: String!) {
                    getAppDefinitions(projectId: $projectId, id: $id) {
                        id
                        type
                        name
                        projectId
                        applicationId
                        workflowId
                        state
                        settings
                        meta
                        settingValues
                        iconFileId
                        capabilities
                        lastUpdatedBy
                        lastUpdatedTime

                        ... on ApplicationDefinition {
                            bindings
                        }

                        ... on ApplicationDefinitionConditional {
                            conditions
                            inputGroups
                        }
                    }
                }
            `,
            variables: {
                projectId: projectId,
                id: id
            },
            fetchPolicy: 'network-only'
        });

        if (result.errors) {
            console.log(result.errors);
            throw result.errors[0];
        }

        const arr = result.data.getAppDefinitions.map(ApplicationDefinitionFactory.createApplicationDefinition)|| [];

        if (!arr.length) {
            return null;
        } 

        return arr[0];
    }

    async getMetadata(): Promise<IotaExtension[]> {
        const url = process.env.REACT_APP_IOTA_URL + 'metadata';
        const resp = await appClient.get<IotaExtension[]>(url);
        return resp.unwrapOr(Array<IotaExtension>());
    }

    async syncMetadata(projectId: string): Promise<void> {
        const url = process.env.REACT_APP_MANAGE_URL + `projects/${projectId}/iota/metadata/sync`;
        await appClient.post(url);
    }

    async syncSettings(projectId: string): Promise<void> {
        const url = process.env.REACT_APP_MANAGE_URL + `projects/${projectId}/iota/applications/sync-settings`;
        await appClient.post(url);
    }

    createApplicationDefinition(formData: FormData, projectId: string): Promise<ResultApi<ApplicationDefinitionSaveSuccessModel>> {
        const url = process.env.REACT_APP_MANAGE_URL + `projects/${projectId}/iota/applications`;
        return appClient.post<ApplicationDefinitionSaveSuccessModel>(url, formData);
    }

    async deleteApplicationDefinition(appDefId: string, projectId: string): Promise<ResultApi<unknown>> {
        const url = `${process.env.REACT_APP_MANAGE_URL}projects/${projectId}/iota/applications/${appDefId}`;
        return appClient.delete(url);
    }

    async editApplicationDefinition(formData: FormData, appDefId: string, projectId: string): Promise<ResultApi<ApplicationDefinitionSaveSuccessModel>> {
        const url = `${process.env.REACT_APP_MANAGE_URL}projects/${projectId}/iota/applications/${appDefId}`;
        return appClient.update<ApplicationDefinitionSaveSuccessModel>(url, formData) ;
    }

    async updateBindings(projectId: string, appDefId: string, bindings: InputBinding[]) {
        const request = {
            bindings: bindings
        };

        const url = `${process.env.REACT_APP_MANAGE_URL}projects/${projectId}/iota/applications/${appDefId}/inputs`;
        return appClient.post(url, request) ;
    }
    
    async updateApplicationSettings(appDefId: string, settings: {[id: string]: unknown}, projectId: string): Promise<ResultApi<unknown>> {
        const request = {
            settingValues: settings
        };

        const url = `${process.env.REACT_APP_MANAGE_URL}projects/${projectId}/iota/applications/${appDefId}/settings`;
        return appClient.update(url, request) ;
    }

    async updateApplicationState(appDefId: string, state: string, projectId: string): Promise<ResultApi<unknown>> {
        const request = {
            applicationId: appDefId,
            state: state
        };

        const url = `${process.env.REACT_APP_MANAGE_URL}projects/${projectId}/iota/applications/${appDefId}/state`;
        return appClient.update(url, request);
    }

    async getApplicationIcon(appDefId: string, projectId: string): Promise<Blob | undefined> {
        const url = `${process.env.REACT_APP_MANAGE_URL}projects/${projectId}/iota/applications/${appDefId}/icon`;
        const resp = await appClient.get<Blob | undefined>(url, 'blob');
        return resp.unwrapOr(undefined);
    }
    
    async applicationDefinitionPreviewBinding(
        projectId: string,
        appDefId: string,
        inputBindings: InputBinding[],
        packageIds: string[]
    ): Promise<ResultApi<Array<ApplicationDefinitionPreviewBindingResponse>>> {
        const request = {
            inputs: inputBindings,
            packageIds
        };

        const url = process.env.REACT_APP_MANAGE_URL + `projects/${projectId}/iota/applications/${appDefId}/preview-binding`;
        return await appClient.post(url, request);
    }

    async createApplicationDefinitionGroup(folder: ApplicationDefinitionsGroupModel, projectId: string, isSubGroupCreated: boolean): Promise<void> {
        const request = {
            isSubGroupCreated: isSubGroupCreated,
            applicationDefinitionsGroup: folder
        };

        const url = process.env.REACT_APP_MANAGE_URL + `projects/${projectId}/iota/applications/group`;
        await appClient.post(url, request);
    }

    async updateApplicationDefinitionGroup(projectId: string, id: string, name: string) {
        const request = {
            id: id,
            name: name
        };

        const url = process.env.REACT_APP_MANAGE_URL + `projects/${projectId}/iota/applications/group`;
        return appClient.update(url, request);
    }

    async exportApp(id: string, projectId: string) {
        const mapForm = document.createElement('form');
        mapForm.setAttribute('id', 'iotaPostForm');
        mapForm.target = '_blank';
        mapForm.method = 'POST';
        const mapIotaIdInput = document.createElement('input');
        mapIotaIdInput.type = 'text';
        mapIotaIdInput.name = 'id';
        mapIotaIdInput.value = id;
        mapForm.appendChild(mapIotaIdInput);

        await security.invoke((token) => {
            let t = '?access_token=' + encodeURIComponent(token);
            const url = process.env.REACT_APP_MANAGE_URL + `projects/${projectId}/iota/applications/export${t}`;
            Utils.downloadFile(url, mapForm, 'iotaPostForm');
            return Promise.resolve();
        });
    }

    async getApplicationDefinitionGroupsForProject(projectId: string): Promise<ApplicationDefinitionsGroupModel[]> {
        const result = await execQuery<ApplicationDefinitionGroupsResult>({
            query: gql`query getAppDefinitionGroups($projectId:String!) {
                  getAppDefinitionGroups(projectId: $projectId) {
                    id
                    name
                    projectId
                    applicationDefinitionIds
                    subGroups
                  }
            }`,
            variables: {
                projectId: projectId
            },
            fetchPolicy: 'network-only'
        });
        if (result.errors) {
            console.log(result.errors);
            throw result.errors[0];
        }

        return result.data.getAppDefinitionGroups || [];
    }

    async getApplicationDefinitionGroupById(projectId: string, id: string): Promise<ApplicationDefinitionsGroupModel | null> {
        const result = await execQuery<ApplicationDefinitionGroupsResult>({
            query: gql`query getAppDefinitionGroups($projectId:String!, $id:String!) {
                  getAppDefinitionGroups(projectId: $projectId, id: $id) {
                    id
                    name
                    projectId
                    applicationDefinitionIds
                  }
            }`,
            variables: {
                projectId: projectId,
                id: id
            },
            fetchPolicy: 'network-only'
        });
        if (result.errors) {
            console.log(result.errors);
            throw result.errors[0];
        }
       
        const arr = result.data.getAppDefinitionGroups || [];
        if (!arr.length) {
            return null;
        } 

        return arr[0];

    }

    async deleteApplicationDefinitionGroup(projectId: string, id: string) {
        const url = process.env.REACT_APP_MANAGE_URL + `projects/${projectId}/iota/${id}/applications/group`;
        return appClient.delete(url);
    }

    async moveApplicationDefinition(projectId: string, appDefid: string, groupId: string) {
        const request = {
            appDefid: appDefid,
            groupId: groupId
        };
        const url = process.env.REACT_APP_MANAGE_URL + `projects/${projectId}/iota/applications/movedefinition`;
        return appClient.post(url, request);
    }

    async getExternalApiOperations(apiUrl: string) {
        const encodedUrl = encodeURIComponent(apiUrl);
        const url = process.env.REACT_APP_IOTA_URL + `external-api-clients?url=${encodedUrl}`;
        const resp = await appClient.get<ApiOperationInfo[]>(url);
        return resp.unwrapOr([]);
    }

    async createApiApplicationDefinition(model: ApiApplicationCreateModel, projectId: string): Promise<ResultApi<ApplicationDefinitionSaveSuccessModel>> {
        const url = process.env.REACT_APP_MANAGE_URL + `projects/${projectId}/iota/api-applications`;
        return appClient.post<ApplicationDefinitionSaveSuccessModel>(url, model);
    }

    getAuthToken() {
        return security.invoke((token) => {
            return Promise.resolve(token);
        });
    }

    async exportAppBindings(id: string, projectId: string) {
        const mapForm = document.createElement('form');
        mapForm.setAttribute('id', 'iotaBindingsPostForm');
        mapForm.target = '_blank';
        mapForm.method = 'POST';
        const mapIotaIdInput = document.createElement('input');
        mapIotaIdInput.type = 'text';
        mapIotaIdInput.name = 'id';
        mapIotaIdInput.value = id;
        mapForm.appendChild(mapIotaIdInput);

        await security.invoke((token) => {
            let t = '?access_token=' + encodeURIComponent(token);
            const url = process.env.REACT_APP_MANAGE_URL + `projects/${projectId}/iota/applications/${id}/export-bindings${t}`;
            Utils.downloadFile(url, mapForm, 'iotaBindingsPostForm');
            return Promise.resolve();
        });
    }

    async commitAppBindings(id: string, projectId: string, file: RcFile) {
        var formData = new FormData();
        formData.append('file', file);
        const url = process.env.REACT_APP_MANAGE_URL + `projects/${projectId}/iota/applications/${id}/commit-bindings`;
        return appClient.post(url, formData);
    }

    async validateConnectionAndSchema(projectId: string, connectionString: string, tableSchema: string) {
        const url = process.env.REACT_APP_MANAGE_URL + `projects/${projectId}/iota/applications/validate-connection-and-schema`;
        return appClient.post(url, { connectionString, tableSchema });
    }
}