import { LIST_PAGE_SIZE } from '../../common/stores/Pager';
import { observable, action, computed, runInAction, reaction, autorun } from 'mobx';
import { ProjectMetadataVisualStore } from './ProjectMetadataVisualStore';
import { AdministrationService } from '../../project_management/services/AdministrationService';
import _ from 'lodash';
import ProjectsRootVisualStore from '../../common/stores/ProjectsRootVisualStore';
import { Package, PackageState } from '../../common/models';
import { PackagesResponse, SearchPackagesAutoCompleteSourceItem, PackageChange, OrderBy } from '../../common/types';
import { ProjectsService } from '../../common/services';
import { ErrorStore } from '../../common/stores';
import { CellMeasurerCache } from 'react-virtualized';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { message } from 'antd';
import { PackageSource } from '../../common/models/PackageSource';
import { GlobalAdministrationService } from '../../administration/service/GlobalAdministrationService';
import { AlphaConfig } from '../../administration/models/AlphaConfig';
import { DefaultAlphaConfigurations } from '../../common/constants';
import { LocalStorageService } from '../../common/services';
import { ALPHA_CONFIG_VARIABLES } from '../../administration/types/AlphaConfigModel';

export const SEARCH_DEBOUNCE_TIME = 500;

// When modifiyng filters increase version number to prevent conflicts with old cached data
const packageFilterStorageVersion = 1;

type PackageFilterStorageValues = {
    pageSize: number;
    currentPage: number;
    searchTerm: string;
    currentTags: string[];
    currentState: string[];
    isPackageSourceChecked: boolean;
    isProtectedOnlyChecked: boolean;
    orderBy: OrderBy
};

const defaultFilterValues = {
    pageSize: LIST_PAGE_SIZE,
    currentPage: 0,
    searchTerm: '',
    currentTags: [] as string[],
    currentState: [] as string[],
    isPackageSourceChecked: false,
    isProtectedOnlyChecked: false,
    orderBy: undefined
} as const;

type PackageFilterStorageItem = Partial<PackageFilterStorageValues>;

interface IsOperationInProcess { 
    [key: number]: boolean
}

export class UploadedPackagesVisualStore { 
    @observable
    isLoading: boolean = false;

    @observable
    isReindexingInProcess: IsOperationInProcess = {};

    @observable
    isCleanupInProcess: IsOperationInProcess = {};

    @observable
    searchTerm: string = '';

    @observable
    filterQuery: string;

    @observable
    isPackageSourceChecked: boolean = false; 

    @observable
    isProtectedOnlyChecked: boolean = false; 

    @observable
    isReparseInProcess: IsOperationInProcess = {};

    @observable
    currentTags: string[] = [];

    @observable
    currentState: string[] = [];

    @observable
    autocompleteSource: SearchPackagesAutoCompleteSourceItem[] = [];

    @observable
    uploadDocsDialogVisible: boolean = false;

    @observable
    selectedPackages: string [] = [];

    @observable
    defaultAlphaConfigs: AlphaConfig[] = [];

    @observable 
    currentProjectPackages: Package[] = [];

    @observable
    totalPackagesInProject: number = 0;

    @observable
    currentPage: number = 0;

    @observable
    orderBy: OrderBy | undefined = undefined;

    @observable
    pageSize: number = LIST_PAGE_SIZE;

    @observable
    bulkDeleteInProcess: boolean = false;

    cache: CellMeasurerCache;

    private searchForAutocompleteCallServer: (s: string) => Promise<void>;

    private readonly localStorageService = new LocalStorageService();

    @computed
    get packages(): Package[] {
        return this.currentProjectPackages.filter(x => this.isPackageSourceChecked ? x.source === PackageSource.Portal : x);
    }

    @computed
    get tags() {
        return this.projectStore.tags || [];
    }

    @computed
    get currentProject() {
        return this.rootStore.currentProject;
    }

    @computed
    get busyPackages() {
        if (this.packages) {
            const busyPackages = this.packages.filter(p => p.state !== PackageState.Ready && p.state !== PackageState.Broken);
            let packagesDictionary = {};
            busyPackages.forEach(p => {
                packagesDictionary[p.id] = p;
            });
            return packagesDictionary;
        }

        return {};
    }

    @computed
    get isGlobalReindexingInProcess() {
        return !!this.selectedPackages.find(x=> !!this.isReindexingInProcess[x]);
    }

    @computed
    get isGlobalCleanupInProcess() {
        return !!this.selectedPackages.find(x=> !!this.isCleanupInProcess[x]);
    }

    @computed
    get isProtectedPackageSelected() {
        return this.selectedPackages.length > 0 && this.selectedPackages.some(p => this.currentProjectPackages.find(x => x.id === p)?.isProtected);
    }

    @computed
    get isUnprotectedPackageSelected() {
        return this.selectedPackages.length > 0 && this.selectedPackages.some(p => this.currentProjectPackages.find(x => x.id === p)?.isProtected !== true);
    }

    @computed
    get isPackageWithApkgSelected() {
        return this.selectedPackages.length > 0 && 
            this.selectedPackages.some(p => this.currentProjectPackages.find(x => x.id === p)?.featureFlags.general[ALPHA_CONFIG_VARIABLES.OcrPackageUploadEnabled] === 'true');
    }

    @computed
    get selectionDownloadLabel() {
        if (!this.selectedPackages.length) {
            return '';
        }
        const names = this.selectedPackages.map(x=> this.currentProjectPackages.find(y=> y.id === x)).filter(x=> x).map(z=> z!.name.split('.').pop());
        return names.every(x=> x === names[0]) ? names[0] : 'originals';
    }

    @computed
    get protectedPackageIndexes() {
        return this.currentProjectPackages.reduce<number[]>((acc, pkg, index) => {
            if (pkg.isProtected) {
                acc.push(index);
            }

            return acc;
        }, []);
    }

    @computed
    get filtersModified() {
        return (
            this.pageSize !== defaultFilterValues.pageSize ||
            this.currentPage !== defaultFilterValues.currentPage ||
            this.searchTerm !== defaultFilterValues.searchTerm ||
            this.currentTags.length > 0 ||
            this.currentState.length > 0 ||
            this.isPackageSourceChecked !== defaultFilterValues.isPackageSourceChecked ||
            this.isProtectedOnlyChecked !== defaultFilterValues.isProtectedOnlyChecked ||
            this.orderBy !== defaultFilterValues.orderBy
        );
    }

    constructor(private rootStore: ProjectMetadataVisualStore, private service: ProjectsService, private projectStore: ProjectsRootVisualStore, 
                private adminService: AdministrationService, private errorStore: ErrorStore, private globalAdministrationService: GlobalAdministrationService) {
        this.projectStore.projectsStore.packageChanges.subscribe((p) => {
            this.updatePackage(p); 
        });

        reaction(
            () => this.currentProject,
            () => {
                if (!this.currentProject) {
                    return;
                }

                const storedFilters = this.localStorageService.getItem<PackageFilterStorageItem>(
                    this.getPackageFilterStorageKey(this.currentProject.id),
                    {}
                );

                runInAction(() => {
                    this.pageSize = storedFilters.pageSize ?? defaultFilterValues.pageSize;
                    this.currentPage = storedFilters.currentPage ?? defaultFilterValues.currentPage;
                    this.searchTerm = storedFilters.searchTerm ?? defaultFilterValues.searchTerm;
                    this.currentTags = storedFilters.currentTags ?? defaultFilterValues.currentTags;
                    this.currentState = storedFilters.currentState ?? defaultFilterValues.currentState;
                    this.isPackageSourceChecked = storedFilters.isPackageSourceChecked ?? defaultFilterValues.isPackageSourceChecked;
                    this.isProtectedOnlyChecked = storedFilters.isProtectedOnlyChecked ?? defaultFilterValues.isProtectedOnlyChecked;
                    this.orderBy = storedFilters.orderBy ?? defaultFilterValues.orderBy;
                });

                this.performSearch(this.searchTerm);
            }
        );

        autorun(() => {
            if (!this.currentProject) {
                return;
            }

            this.localStorageService.setItem<PackageFilterStorageItem>(
                this.getPackageFilterStorageKey(this.currentProject.id),
                {
                    pageSize: this.pageSize,
                    currentPage: this.currentPage,
                    searchTerm: this.searchTerm,
                    currentTags: this.currentTags,
                    currentState: this.currentState,
                    isPackageSourceChecked: this.isPackageSourceChecked,
                    isProtectedOnlyChecked: this.isProtectedOnlyChecked,
                    orderBy: this.orderBy
                }
            );
        });
        
        this.searchForAutocompleteCallServer = _.debounce(this.searchForAutocompleteImp, 200);

        this.fetchDefaultConfigValues();
    }

    @action.bound
    clearFilters() {
        runInAction(() => {
            this.pageSize = defaultFilterValues.pageSize;
            this.currentPage = defaultFilterValues.currentPage;
            this.searchTerm = defaultFilterValues.searchTerm;
            this.currentTags = defaultFilterValues.currentTags;
            this.currentState = defaultFilterValues.currentState;
            this.isPackageSourceChecked = defaultFilterValues.isPackageSourceChecked;
            this.isProtectedOnlyChecked = defaultFilterValues.isProtectedOnlyChecked;
            this.orderBy = defaultFilterValues.orderBy;
        });

        this.performSearch(this.searchTerm);
    }

    @action.bound
    setCurrentPage(page: number) {
        this.currentPage = page - 1;
        this.loadProjectPackages(this.searchTerm);
    }

    @action.bound
    setOrderBy(orderBy: OrderBy | undefined) {
        this.orderBy = orderBy;
    }

    @action.bound
    setPageSize(pageSize?: number) {
        if (!pageSize) {
            return;
        }

        this.pageSize = pageSize;
    }
    
    @action.bound
    async fetchDefaultConfigValues() {
        const configs = await this.globalAdministrationService.getAlphaConfigs();
        let alphaConfigFlags: AlphaConfig[] = [];

        for(let conf of DefaultAlphaConfigurations) { 
            const config = configs.find(x => x.name === conf.key);
            alphaConfigFlags.push({
                id: config ? config.id : conf.key,
                name: conf.key,
                value: config ? config.value : conf.defaultValue
            });
        }
        runInAction(() => {
            this.defaultAlphaConfigs = alphaConfigFlags;
        });
    }

    @action.bound
    setUploadDocsDialogVisible(visible: boolean) {
        this.uploadDocsDialogVisible = visible;
    }

    @action.bound
    setSearchTerm(pkgName: string) {
        this.searchTerm = pkgName;
    }

    @action
    async searchForAutocomplete(term: string) {
        this.searchTerm = term;
        return this.searchForAutocompleteCallServer(this.searchTerm?.trim());
    }

    @action
    performSearch(term: string = '') {
        this.searchTerm = term;
        this.filterQuery = `${this.searchTerm}`;
        runInAction(() => {
            this.currentPage = 0;
        });
        this.loadProjectPackages(this.searchTerm.trim());
    }

    @action.bound
    resetPackagesSelection() {
        this.selectedPackages = [];
    }

    @action.bound
    handlePackageSourceCheck (e: CheckboxChangeEvent) {
        this.isPackageSourceChecked = e.target.checked;
        this.performSearch(this.searchTerm);
    }

    @action.bound
    handleIsProtectedOnlyChecked (e: CheckboxChangeEvent) {
        this.isProtectedOnlyChecked = e.target.checked;
        this.performSearch(this.searchTerm);
    }

    @action.bound
    async handlePackagesReparse() {
        try {
            const prevState = new Map<string, PackageState>();

            runInAction(() => {
                this.currentProjectPackages
                    .filter(x => this.selectedPackages.includes(x.id))
                    .forEach(p => {
                        prevState.set(p.id, p.serverState);
                        p.updatePackageStateResult(PackageState.Busy);
                    });

                this.currentProjectPackages = [...this.currentProjectPackages];
            });

            const result = await this.adminService.parsePackagesBatch(this.selectedPackages);

            if (result.isErr()) {
                message.error('Failed to parse packages');
                console.error(result.mapErr((error) => error.text));

                runInAction(() => {
                    this.currentProjectPackages.forEach(p => {
                        const state = prevState.get(p.id);

                        if (state) {
                            p.updatePackageStateResult(state);
                        }
                    });

                    this.currentProjectPackages = [...this.currentProjectPackages];
                });
                return;
            } else {
                this.resetPackagesSelection();
            }
        } catch (err) {
            console.error(err);
        }
    }

    @action.bound
    async handlePackagesReindex() {
        const promises = this.selectedPackages.map(x=> this.reindexPackage(x));
        this.resetPackagesSelection();
        await Promise.all(promises);
    }

    @action.bound
    async handlePackagesDelete() {
        const promises = this.selectedPackages.map(x=> this.deletePackage(x));
        await Promise.all(promises);
        this.selectedPackages = [];
    }

    
    @action.bound
    async handlePackagesCleanup() {
        const promises = this.selectedPackages.map(x=> this.cleanStorage(x));
        await Promise.all(promises);
    }

    @action.bound
    handleSelection(selectedKeys: []) {
        if (selectedKeys.length) {
            this.selectedPackages = this.currentProjectPackages.map(x=> x.id);
        } else {
            this.selectedPackages = [];
        }
        // this.selectedPackages = selectedKeys;
    }

    @action
    async reparsePackage(packageId: string) {
        this.isReparseInProcess[packageId] = true;
        this.isReindexingInProcess[packageId] = true;
        await this.adminService.parseByPackage(packageId);
        runInAction(() => this.isReindexingInProcess[packageId] = false);
    }

    @action
    async reindexPackage(packageId: string) {
        this.isReindexingInProcess[packageId] = true;
        const pkgToUpdate = this.currentProjectPackages.find(p => p.id === packageId);
        if (pkgToUpdate && this.currentProject) {
            this.projectStore.updatePackageStateInCurrentProject(this.currentProject.packages.indexOf(pkgToUpdate), PackageState.Busy);
        }
        await this.adminService.reindexByPackage(packageId);
        runInAction(() => this.isReindexingInProcess[packageId] = false);
    }

    @action.bound
    async updatePackagesProtection(packageIds: string[], isProtected: boolean) {
        const pkgsToUpdate = this.currentProjectPackages.filter(p => packageIds.includes(p.id));
        if (pkgsToUpdate.length === 0) {
            return;
        }

        const resp = await this.adminService.updatePackagesProtection(packageIds, isProtected);

        if (resp.isOk()) {
            pkgsToUpdate.forEach(p => p.updatePackageProtection(isProtected));
            message.success('Packages protection updated');
        } else {
            message.error('Failed to update packages protection');
        }
    }

    @action.bound
    async deletePackage(id: string) {
        const pkg = this.currentProjectPackages.find(p => p.id === id)!;
        if (pkg.operationState && _.includes(pkg.operationState, 'UserReviewed') ) {
            message.warning('Can\'t delete current package as it has labels linked to it');
            return;
        }
        const resp = await this.adminService.deletePackage(id);
        runInAction(() => {
            if (this.searchTerm) {
                this.autocompleteSource = this.autocompleteSource.filter(a=> a.filePath !== pkg.documentPath);
            }
            if (this.selectedPackages != null && this.selectedPackages.some(p => p === id)) {
                this.selectedPackages = this.selectedPackages.filter(p => p !== id);
            }
        });
        if (resp.isOk()) { 
            resp.map(() => this.removePackageFromList(id));
            message.success(`Deleted package '${pkg.name}' successfully`);
        } else {
            message.error('Failed to delete package');
            console.error(resp.error);
        }
    }

    @action
    async bulkDeletePackagesByDateRange(startDate: string, endDate: string) {
        try {
            if (!this.currentProject) {
                return;
            }

            this.bulkDeleteInProcess = true;

            message.success('Deletion of packages has started');

            const resp = await this.adminService.bulkDeletePackagesByDateRange(this.currentProject.id, startDate, endDate);

            if (resp.isOk()) {
                message.success('Packages deleted successfully');
                this.loadProjectPackages(this.searchTerm);
            } else {
                message.error('Failed to delete packages');
            }
        } finally {
            this.bulkDeleteInProcess = false;
        }
    }

    @action.bound
    removePackageFromList(id: string) {
        this.currentProjectPackages = this.currentProjectPackages.filter(p => p.id !== id);
    }

    @action
    async cleanStorage(packageId: string) {
        this.isCleanupInProcess[packageId] = true;
        await this.adminService.cleanStorage(packageId);
        runInAction(() => this.isCleanupInProcess[packageId] = false);
    }

    @action.bound
    setTags(tags: string[]) {
        this.currentTags = tags;
        this.performSearch(this.searchTerm);
    }

    @action.bound
    setState(state: string[]) {
        this.currentState = state;
    }

    @action.bound
    async updatePackageUserTags(pkg: Package, tags: string[]) {
        try {
            await this.service.updatePackageUserTags(pkg, tags);
            await this.projectStore.fetchTags();

            runInAction(() => {
                if (this.projectStore.currentProject) {
                    var pkgInProj = this.projectStore.currentProject.packages.find(p => p.id === pkg.id);
                    if (pkgInProj) {
                        pkgInProj.userTags = tags;
                    }
                }
            });
        } catch (error) {
            this.errorStore.addBasicError(error);
        }
    }

    @action.bound
    handlePackageSelection(e: CheckboxChangeEvent, packageId: string) {
        if (e.nativeEvent.shiftKey) {
            this.handleMultipleSelection(e.target.checked, packageId);
            return;
        }

        if (e.target.checked) {
            this.selectedPackages.push(packageId);
        } else {
            const index = this.selectedPackages.indexOf(packageId);
            this.selectedPackages.splice(index, 1);
        }
    }

    @action.bound
    handleMultipleSelection(selected: boolean, packageId: string) {
        const packageIds = this.currentProjectPackages.map(p => p.id);
        const indexOfCurrentPackage = packageIds.indexOf(packageId);
        const indexOfLastSelectedPackage = packageIds.indexOf(this.selectedPackages[this.selectedPackages.length - 1]);
        let packagesToChange: string[] = [];

        if (indexOfCurrentPackage > indexOfLastSelectedPackage) {
            packagesToChange = packageIds.slice(indexOfLastSelectedPackage, indexOfCurrentPackage + 1);
        } else {
            packagesToChange = packageIds.slice(indexOfCurrentPackage, indexOfLastSelectedPackage + 1);
        }

        if (selected) {
            this.selectedPackages = [...new Set(this.selectedPackages.concat(packagesToChange))];

        } else {
            this.selectedPackages = this.selectedPackages.filter(p => !packagesToChange.includes(p));
        }
    }

    @action.bound
    async updatePackageFeatureFlags(packageId: string, featureFlags: unknown) {
        try {
            const resp = await this.service.updatePackageFeatureFlags(packageId, featureFlags);

            if (resp.isOk()) {
                message.success('Package feature flags updated. Started reparsing.');
                return true;
            } else {
                message.error('Failed to update package feature flags');
                return false;
            }
        } catch (error) {
            this.errorStore.addBasicError(error);
            return false;
        }
    }

    @action.bound
    async exportPackageFeautureFlags(packageId: string) {
        await this.service.exportPackageFeautureFlags(packageId);
    }
    
    @action.bound
    async loadProjectPackages(term: string | null = null) {
        try {
            runInAction(() => {
                this.isLoading = true;
            });
            const result = await this.searchInProject(term);

            runInAction(() => {
                this.currentProjectPackages = result.lines;
                this.totalPackagesInProject = result.total;
            });
        } catch (err) {
            message.error('Error loading packages');
            console.error(err);
        } finally {
            runInAction(() => {
                this.isLoading = false;
            });
        }
    }

    @action
    private async searchForAutocompleteImp(term: string) {
        const request = {
            projectId: this.rootStore.currentProject!.id,
            search: term,
            page: 0,
            pageSize: 15,
            allSources: this.isPackageSourceChecked ? false : true,
            orderBy: this.orderBy,
            uploadedBy: this.projectStore.hasAccessToAllEntities ? null : this.projectStore.currentUserId
        };

        const result = await this.service.searchPackages(this.rootStore.currentProject!, request);

        if (!result) {
            runInAction(() => {
                this.autocompleteSource = [];
            });            
            return;
        }

        runInAction(() => {
            this.autocompleteSource = result.lines.map(l => ({
                fileName: l.name,
                filePath: l.documentPath!,
                state: l.state
            }));
        });
    }
    
    @action.bound
    private updatePackage(pkgChanges: PackageChange) {
        if (!this.currentProject || pkgChanges.projectId !== this.currentProject.id) {
            return;
        }

        const pkgToUpdate = this.currentProjectPackages.find(p => p.id === (pkgChanges.id));
        if (!pkgToUpdate && this.packageChangesShouldBeInserted(pkgChanges)) {
            const newPkg = new Package(this.rootStore.currentProject!, pkgChanges.id,
                pkgChanges.fileName, 'application/pdf', pkgChanges.state, pkgChanges.filePath, [], 
                pkgChanges.userTags, pkgChanges.source, pkgChanges.uploadDate , pkgChanges.indexDate, pkgChanges.error,
                pkgChanges.featureFlags, pkgChanges.isProtected);

            let newPackages = [...this.currentProjectPackages];
            newPackages.unshift(newPkg);
            this.currentProjectPackages = newPackages;
        } else {
            this.updateStateOfExistingPackage(pkgChanges.id, pkgChanges.state);
            if (pkgChanges.state === PackageState.Ready || pkgChanges.state === PackageState.Broken) {
                this.resetUiState(pkgChanges.id);
                this.projectStore.reloadCurrentProject();
                this.loadProjectPackages(this.searchTerm);
            }
        }
    }

    @action.bound
    private updateStateOfExistingPackage(packageId: string, state: PackageState) {
        const pkgToUpdate = this.currentProjectPackages.find(p => p.id === (packageId));
        if (!pkgToUpdate) {
            return;
        }

        let newPackages = [...this.currentProjectPackages];
        const index = newPackages.indexOf(pkgToUpdate);
        newPackages[index].updatePackageStateResult(state);
        this.currentProjectPackages = newPackages;
    }

    @action
    private resetUiState(id: string) {
        this.isReparseInProcess[id] = false;
    }

    handlePackagesDownload(fileType: 'pdf'| 'apkg') {
        if (this.selectedPackages.length === 1) {
            return this.service.handleDownload(this.selectedPackages[0], fileType);
        } 
        return this.service.handleArchiveDownload(this.selectedPackages, fileType);
    }

    async getPackageCountByDateRange(startDate: string, endDate: string) {
        return this.currentProject ? await this.adminService.getPackageCountByDateRange(this.currentProject.id, startDate, endDate) : 0;
    }

    private packageChangesShouldBeInserted(pkgChanges: PackageChange) {
        const includedInTags = pkgChanges.userTags.some(t => this.currentTags.includes(t)) || !this.currentTags.length;
        const nameSatisfiesCriteria = pkgChanges.fileName.toLowerCase().includes(this.searchTerm.toLowerCase()) || !this.searchTerm || this.searchTerm.trim() === '';
        const sourceIsPortal = this.isPackageSourceChecked ? pkgChanges.source === PackageSource.Portal : true;
        return includedInTags && nameSatisfiesCriteria && sourceIsPortal;
    }
    
    private searchInProject(term: string | null): Promise<PackagesResponse> {
        if (!this.rootStore.currentProject) {
            return Promise.resolve({ lines: [], total: 0 });
        }

        const request = {
            projectId: this.rootStore.currentProject!.id,
            search: term,
            page: this.currentPage,
            pageSize: this.pageSize,
            tags: this.currentTags,
            allSources: this.isPackageSourceChecked ? false : true,
            orderBy: this.orderBy,
            state: this.currentState,
            protectedOnly: this.isProtectedOnlyChecked,
            uploadedBy: this.projectStore.hasAccessToAllEntities ? null : this.projectStore.currentUserId
        };

        return this.service.searchPackages(this.rootStore.currentProject!, request);
    }

    private getPackageFilterStorageKey(projectId: string) {
        return `package-filter-${projectId}-v${packageFilterStorageVersion}`;
    }
}

export default UploadedPackagesVisualStore;