import * as React from 'react';
import { Button, Tree, Modal, Tooltip, Input, Menu, Dropdown, Upload, message, Skeleton, Popconfirm } from 'antd';
import { observer, Observer } from 'mobx-react-lite';
import FormItem from 'antd/lib/form/FormItem';
import { DropTarget, OnHitEventArgs, DragDropContainer } from 'react-drag-drop-container';
import { IotaAppStores } from '../stores';
import ApplicationDefinitionsGroupModel from '../types/ApplicationDefinitionsGroupModel';
import { HasPermission } from '../../authorization/components/HasPermission';
import { AppPermissions } from '../../authorization/Permissions';
import { FileSyncOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import Form, { FormInstance } from 'antd/lib/form';
import { DataNode } from 'antd/lib/tree';
import { UploadChangeParam } from 'antd/lib/upload';
import { RulesStores } from '../../rules/stores';
import RulesImportDialog from '../../rules/screens/RulesImportDialog';
import { ApiApplicationDialog } from '.';

type Props = {
    form: FormInstance
} & IotaAppStores & RulesStores;

export const ApplicationGroupingTree: React.FC<Props> = observer(({form, projectApplicationDefinitionsUI: store, RulesImportUI : rulesImportStore}) => {
    const treeStore = store!.groupTreeStore;
    const [isLoading, setIsLoading] = React.useState(true);

    React.useEffect(() => {
        let isMounted = true;
        treeStore!.loadApplicationDefinitionGroups().then(() => {
            if (isMounted) {
                setIsLoading(false);
            }
        }).catch((ex) => {
            console.error(ex);
            setIsLoading(false);
        });

        return(() => {
            isMounted = false;
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    const [highlight, setHighlight] = React.useState('unset');

    const handleCancel = () => {
        treeStore!.folderToInsertId = '';
        treeStore!.setAddEditGroupDialogVisible(false);
    };

    const handleSubmit = async () => {
        await treeStore!.saveApplicationDefinitionGroup(form.getFieldValue('name'));
    };

    const handleEditSettingsClick = (id: string) => {
        const appDef = store!.applicationDefinitions.find( a => a.id === id);
        store!.goToSettingsPage(appDef!);
    };

    const handleAppStateUpdate = async (id: string, state: string) => {        
        const newState = state === 'Draft' ? 'Published' : 'Draft';
        await store!.updateApplicationState(id, newState);        
    };

    const editApplicationDefGroup = (id: string) => {
        treeStore!.openGroupFormDialog(id, () => {
            form.setFieldsValue({name: treeStore!.currentAppGroup!.name}); 
        });
    };

    const addApplicationDefGroup = () => {
        treeStore!.openGroupFormDialog(undefined, () => {
            form.setFieldsValue({name: undefined}); 
        });
    };

    const dialog =  (
        <Modal
            title={treeStore!.currentAppGroup ? `Edit ${treeStore!.currentAppGroup.name}` : treeStore.folderToInsertId ? 'Add sub-group' : 'Add group'}
            visible={treeStore!.addEditGroupDialogVisible}
            onCancel={handleCancel}
            maskClosable={false}
            destroyOnClose={false}
            closable={false}
            className="alpha-portal-dialog"
            centered
            footer={[
                <Button data-id="iota-group-dialog-cancel" size="large" className="light" key="back" onClick={handleCancel}>
                  Cancel
                </Button>,
                <Button data-id="iota-group-dialog-submit" size="large" key="submit" type="primary" onClick={handleSubmit}>
                    {treeStore!.currentAppGroup ? 'Save changes' : 'Add group'}
                </Button>
            ]}
        >
            <Form form={form} onFinish={handleSubmit} layout="vertical" data-id="iota-group-dialog-form">
                <FormItem
                    label={(
                        <span>
                            Group name&nbsp;
                            <Tooltip title="Applications group name for alpha">
                                <QuestionCircleOutlined/>
                            </Tooltip>
                        </span>
                    )}
                    name="name"
                    rules={[{
                        required: true, message: 'Please input group application name!', whitespace: true
                    }]}
                >
                    <Input />
                </FormItem>
            </Form>
        </Modal>);

    const addApiApplication = (folderId: string) => {
        treeStore!.folderToInsertId = folderId; 
        store!.setCurrentAppDef(undefined); 
        store?.setIsApiAppDialogVisible(true);
    };
    
    const groupMenu = (id: string, name: string, rootGroupId?: string) => {
        return(
            <Menu data-id-type="popup-menu-list-root" data-id-name={name}> 
                <Menu.Item 
                    data-id-type="popup-menu-list-item"
                    data-id-name="Add sub-group"
                    key="1" 
                    onClick={() => {
                        treeStore!.folderToInsertId = id; addApplicationDefGroup(); 
                    }}
                >
                    Add sub-group
                </Menu.Item>
                <Menu.Item 
                    data-id-type="popup-menu-list-item"
                    data-id-name="Add Application Definition"
                    key="2" 
                    onClick={() => {
                        treeStore!.folderToInsertId = id; store!.setCurrentAppDef(undefined); store!.setAddDefinitionDialogVisible(true); 
                    }}
                >
                    Add Application Definition
                </Menu.Item>
                <Menu.Item 
                    data-id-type="popup-menu-list-item"
                    data-id-name="Add API application"
                    key="2-1" 
                    onClick={() => {
                        addApiApplication(id);
                    }}
                >
                    Add API application
                </Menu.Item>
                <Menu.Item 
                    data-id-type="popup-menu-list-item"
                    data-id-name="Import"
                    key="3" 
                >
                    <HasPermission key="rules-import" entityId={store?.currentProject?.id} permissionClaim={AppPermissions.CanEditIotaApplications}>
                        <Upload 
                            showUploadList={false}
                            className="headerButton"
                            key="file-uploader" 
                            name="file" 
                            onChange={(info: UploadChangeParam) => onChange(info, id, rootGroupId)} 
                            action={`${process.env.REACT_APP_MANAGE_URL}projects/${store!.currentProject!.id}/iota/applications/import`} 
                            headers={store!.fileImportActionHeaders} 
                            beforeUpload={() => store!.setHeaders()}
                        >
                            <div onClick={rulesImportStore!.handleBeforeUpload} style={{width: 200}} data-id="button-Import">Import</div>                        </Upload>
                    </HasPermission>
                </Menu.Item>
                <Menu.Item
                    data-id-type="popup-menu-list-item"
                    data-id-name="Rename"
                    key="4"
                    onClick={() => {
                        editApplicationDefGroup(id); 
                    }}
                >
                    Rename
                </Menu.Item>
                <Popconfirm
                    title="Are you sure you want to delete this application definition group?"
                    onConfirm={() => store!.deleteAppDefinitionGroup(id)}
                >
                    <Menu.Item 
                        data-id-type="popup-menu-list-item"
                        data-id-name="Delete"
                        key="5"
                        danger
                    >
                        Delete
                    </Menu.Item>
                </Popconfirm>
            </Menu>
        );
    };

    const onChange = (info: UploadChangeParam, groupId: string, rootGroupId?: string) => {
        const status = info.file.status;
        if (status === 'done') {        
            const rulesResponse = info.file.response;
            rulesImportStore!.setTableDataForIotaImport(rulesResponse, groupId, rootGroupId);
            message.success(`${info.file.name} file uploaded successfully.`);
        } else if (status === 'error') {
            if (info.file?.response?.status === 409) {
                message.warning(info.file.response.title);
                return;
            }
            message.error(`${info.file.name} file upload failed.`);
        }
    };

    const appDefMenu = (id: string, state: string, activatable: boolean, name: string) => {
        return(
            <Menu data-id-type="popup-menu-list-root" data-id-name={name}>
                <Menu.Item 
                    data-id-type="popup-menu-list-item"
                    data-id-name={state === 'Published' ? 'Deactivate' : 'Activate'}
                    key="1"
                    onClick={() => handleAppStateUpdate(id, state)}
                    disabled={!activatable}
                >
                    {state === 'Published' ? 'Deactivate' : 'Activate'}
                </Menu.Item>
                <Menu.Item 
                    data-id-type="popup-menu-list-item"
                    data-id-name="Edit Application"
                    key="2"
                    onClick={() => store!.handleEditAppClick(id)}
                >
                    Edit Application
                </Menu.Item>
                <Menu.Item 
                    data-id-type="popup-menu-list-item"
                    data-id-name="Edit Inputs"
                    key="3"
                    onClick={() => store!.editInputs(id)}
                >
                    Edit Inputs
                </Menu.Item>
                <Menu.Item 
                    data-id-type="popup-menu-list-item"
                    data-id-name="Edit Settings"
                    key="4" 
                    onClick={() => handleEditSettingsClick(id)}
                >
                    Edit Settings
                </Menu.Item>
                <Popconfirm
                    title="Are you sure you want to delete this application?"
                    onConfirm={() => store!.deleteApplicationDefinition(id)}
                >
                    <Menu.Item 
                        data-id-type="popup-menu-list-item"
                        data-id-name="Delete"
                        key="5"
                        danger
                    >
                        Delete
                    </Menu.Item>
                </Popconfirm>
              
            </Menu>
        );
    };
    const groupDropDown = (folder: ApplicationDefinitionsGroupModel) => {
        return (
            <>
                <DropTarget 
                    targetKey="appDefItem" 
                    onDragEnter={() => {
                        treeStore!.currentHighlightedNodeId = folder.id;  setHighlight('#40a9ff'); 
                    }}
                    onDragLeave={() => setHighlight('unset')} 
                    onHit={(p: OnHitEventArgs) => {
                        treeStore!.moveApplicationDefinition( p, folder.id ); setHighlight('unset'); 
                    }}
                >
                    <Dropdown key={folder.id + 'i'} overlay={() => groupMenu(folder.id, folder.name, folder.rootGroupId)} trigger={['contextMenu']}>

                        <span style={treeStore!.currentHighlightedNodeId === folder.id  ? {backgroundColor: highlight } : {}}> 
                            <span className="node-title with-menu" data-id={`iota-tree-group-${folder.id}`} data-id-name={folder.name} data-id-type="group-0" >{folder.name}</span>
                        </span>
                    </Dropdown>
                </DropTarget>
                <HasPermission entityId={store?.currentProject?.id} permissionClaim={AppPermissions.CanEditIotaApplications}>
                    <Dropdown key={folder.id + 'settings'} overlay={() => groupMenu(folder.id, folder.name, folder.rootGroupId)} trigger={['click']}>
                        <i className="alpha-icon md more-icon node-action" onClick={(e) =>  e.stopPropagation()} />
                    </Dropdown>
                </HasPermission>
            </>
                
        );
    };

    const renderSubNodes = (folders: ApplicationDefinitionsGroupModel[]): DataNode[] => {
        if (!folders) {
            return [];
        } 

        const getTitle = (id: string, name: string, state: string, activatable: boolean) => {  
            return(  
                <HasPermission 
                    entityId={store?.currentProject?.id}
                    permissionClaim={AppPermissions.CanEditIotaApplications}
                    yes={() => (
                        <DragDropContainer targetKey="appDefItem" dragData={{appDefId: id }}>
                            <Dropdown key={id + 'd'} overlay={() => appDefMenu(id, state, activatable, name)} trigger={['contextMenu']}>
                                <span 
                                    style={{
                                        color: state === 'Published' ? 'black' : '#bdbdbd'
                                    }}
                                >
                                    <Tooltip title={name}>
                                        <span className="node-title"  data-id-type="app" data-id-name={name}  data-id={`iota-tree-app-${id}`}>{name}</span>
                                    </Tooltip>
                                </span>
                            </Dropdown>
                        </DragDropContainer >
                    )}
                    no={() => 
                        <span 
                            style={{                                
                                color: state === 'Published' ? 'black' : '#bdbdbd'
                            }}
                        >
                            <span className="node-title">{name}</span>
                        </span>}
                />
            );
        };

        return (folders.map(f =>  {
            const createAppDefNodes = (): DataNode[] => { 
                return (f.applicationDefinitionIds.map( id => {
                    const appDef = treeStore!.appDefinitions.find(a => a.id === id);
                    if (appDef) {
                        return ({
                            key:id,
                            switcherIcon: appDef.applicationId === store?.EXTERNAL_API_APPLICATION_NAME ? <FileSyncOutlined /> : <i className="alpha-icon md document-icon" />,
                            title:getTitle(id, appDef.name, appDef.state, appDef.state === 'Published' ? true : store!.validateSettingsAndInputs(appDef))
                        });
                    } else {
                        return {key:id};
                    }
                }));
                    
            };
            return ({
                selectable: true, 
                key: f.id,
                title: groupDropDown(f),
                children: [...renderSubNodes(f.subGroups), ...createAppDefNodes()]
            });
        }));
    };

    const importCallback = async() => {
        const promises = [store!.loadApplicationDefinitions(), treeStore!.loadApplicationDefinitionGroups()];
        await Promise.all(promises);
    };

    if (isLoading) {
        return (
            <div style={{padding: '0 20px'}}> 
                <Skeleton active />
            </div>
        );
    }

    return(
        <>
            {dialog}
            <RulesImportDialog importCallback={importCallback}/>
            <ApiApplicationDialog store={store!} />
            <Observer>
                {() => (
                    <Tree 
                        treeData={renderSubNodes(treeStore!.folders)}
                        showLine={{showLeafIcon: true}}
                        className="alpha-portal-tree without-line with-leaf-icons sm-labels"
                        onSelect={(keys) => treeStore!.handleNodeSelection(keys.length ? keys[0] as string : null)}  
                        onExpand={(ex: string[]) => treeStore!.expand(ex)} 
                        expandedKeys={treeStore!.expandedKeys}
                    />
                )}
            </Observer> 
        </>
    );
   
});

export default ApplicationGroupingTree;