import * as React from 'react';
import { inject } from 'mobx-react';
import { observer } from 'mobx-react-lite';
import { Input, Form, Row, Col, FormInstance, Checkbox, Select } from 'antd';
import { STORE_RULE_EDIT } from '../constants';
import { ElasticSearchRegexpQueryRule } from '../models';
import { RuleEditVisualStore } from '../stores';
import { RuleEditGeneralSettings } from '.';
import { BlockTypesDropdown } from '../../common/components/BlockTypesDropdown';
import { RegexFlagKey } from '../types';
import RuleType from '../types/RuleType';

type RegexFlag = { value: RegexFlagKey; label: string };

const regexFlags: RegexFlag[] = [
    { value: 'ALL', label: 'ALL' },
    { value: 'COMPLEMENT', label: 'COMPLEMENT (~ operator)' },
    { value: 'EMPTY', label: 'EMPTY (# operator)' },
    { value: 'INTERVAL', label: 'INTERVAL (<>)' },
    { value: 'INTERSECTION', label: 'INTERSECTION (& operator)' },
    { value: 'ANYSTRING', label: 'ANYSTRING (@ operator)' },
    { value: 'NONE', label: 'NONE (Disables all operators)' }
];

type Props = {
    store: RuleEditVisualStore;
    selectType?: (type: RuleType) => void;
    form: FormInstance
};

const RuleEditElasticSearchRegexpQueryForm: React.FC<Props> = ({ store, selectType, form }) => {
    const rule = store.editableRule as ElasticSearchRegexpQueryRule;

    const getInitialValues = React.useCallback(() => {
        return {
            name: rule.name,
            tag: rule.tag,
            value: rule.value,
            flags: rule.flags,
            caseInsensitive: rule.caseInsensitive,
            priority: rule.priority,
            description: rule.description,
            overridePriority: rule.overridePriority,
            excludedBlockTypes: rule.excludedBlockTypes
        };
    }, [rule]);

    React.useEffect(() => {
        form.setFieldsValue(getInitialValues());
    }, [form, getInitialValues]);

    const trimValue = () => {
        const value = form.getFieldValue('value');

        if (value) {
            form.setFieldsValue({ value: value.trim() });
        }
    };

    return (
        <>
            {store.error.map((e, i) => (
                <h4 key={e + i} style={{ color: 'red' }}>
                    {e}
                </h4>
            ))}
            <Form form={form} layout="vertical" className="rule-edit-form" data-id="rule-edit-form">
                <RuleEditGeneralSettings store={store} selectType={selectType} form={form} />
                <Row gutter={16}>
                    <Col className="gutter-row" span={12}>
                        <Form.Item
                            label="Value"
                            name="value"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input value!'
                                }
                            ]}
                            data-id="rule-value-input"
                        >
                            <Input onBlur={trimValue} />
                        </Form.Item>
                    </Col>
                    <Col className="gutter-row" span={12}>
                        <Form.Item label="Flags" name="flags" data-id="rule-flags-select">
                            <Select mode="tags" style={{ width: '100%' }} placeholder="Select regex flags">
                                {regexFlags.map(flag => (
                                    <Select.Option key={flag.value} value={flag.value}>
                                        {flag.label}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col className="gutter-row" span={12}>
                        <BlockTypesDropdown mode="multiple" />
                    </Col>
                    <Col className="gutter-row" span={12}>
                        <Form.Item
                            label={null}
                            name="caseInsensitive"
                            valuePropName="checked"
                            data-id="rule-case-insensitive-checkbox"
                        >
                            <Checkbox style={{ marginTop: 24 }}>Case insensitive</Checkbox>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </>
    );
};

export default inject(STORE_RULE_EDIT)(observer(RuleEditElasticSearchRegexpQueryForm));
